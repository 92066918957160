<template>
    <div class="">
        <el-dialog
                v-loading="listLoading"
                :visible.sync="isActiveTherapyTableHead"
                width="1200px"
                inline
                :close-on-click-modal="false"
                :before-close="handleCancel">
            <div slot="title" style="font-size: 14px;color: #606266;font-weight: 700;margin-bottom: 15px"></div>
            <el-table
                    ref="dragTable"
                    :data="tableData"
                    fit
                    highlight-current-row
                    border
                    style="width: 100%">
                <el-table-column
                        prop="sort"
                        width="50"
                        label="排序"
                        align="center">
                </el-table-column>
                <el-table-column
                        prop="column_prop"
                        label="表头字段"
                        width="150"
                        align="center">
                </el-table-column>
                <el-table-column
                        prop="column_label"
                        label="默认名称"
                        width="120"
                        align="center">
                </el-table-column>
                <el-table-column
                        prop="field_type"
                        label="数据类型"
                        width="160"
                        align="center">
                    <template slot-scope="{row}">
                        <template v-if="row.edit">
                            <el-input v-model="row.field_type" class="edit-input" size="mini"/>
                            <el-button
                                    class="cancel-btn"
                                    size="mini"
                                    icon=""
                                    type="warning"
                                    @click="handleCancelEditLabel(row)"
                            >
                                退出
                            </el-button>
                        </template>
                        <span v-else>{{ row.field_type }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="column_label_user_definition"
                        label="自定义名称"
                        align="center">
                    <template slot-scope="{row}">
                        <template v-if="row.edit">
                            <el-input v-model="row.column_label_user_definition" class="edit-input" size="mini"/>
                            <el-button
                                    class="cancel-btn"
                                    size="mini"
                                    icon=""
                                    type="warning"
                                    @click="handleCancelEditLabel(row)"
                            >
                                退出
                            </el-button>
                        </template>
                        <span v-else>{{ row.column_label_user_definition }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="width"
                        label="自定义宽度"
                        align="center"
                        width="160">
                    <template slot-scope="{row}">
                        <div style="display: flex;justify-content: center; align-items: center">
                            <template v-if="row.edit">

                                <el-input v-model.number="row.width" class="edit-input" size="mini"/>
                                <el-button
                                        class="cancel-btn"
                                        size="mini"
                                        icon=""
                                        type="warning"
                                        @click="handleCancelEditWidth(row)"
                                >
                                    退出
                                </el-button>
                            </template>
                            <span v-else>{{ row.width }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="visible"
                        label="是否显示"
                        align="center"
                        width="80">
                    <template slot-scope="scope">
                        <el-switch
                                class="switch"
                                @change="changeExamine(scope.row)"
                                v-model="scope.row.visible"
                                :active-value="true"
                                :inactive-value="false"
                                active-text="启用"
                                inactive-text="禁用"
                                active-color="#13ce66"
                                inactive-color="#C8C8C8">
                        </el-switch>
                    </template>
                </el-table-column>
              <el-table-column
                  prop="visible"
                  label="是否导出"
                  align="center"
                  width="80">
                <template slot-scope="scope">
                  <el-switch
                      class="switch"
                      @change="changeExport(scope.row)"
                      v-model="scope.row.is_export"
                      :active-value="1"
                      :inactive-value="0"
                      active-text="启用"
                      inactive-text="禁用"
                      active-color="#13ce66"
                      inactive-color="#C8C8C8">
                  </el-switch>
                </template>
              </el-table-column>
                <el-table-column align="center" width="110" label="操作">
                    <template slot-scope="{row}">
                        <el-button
                                v-if="row.edit"
                                type="success"
                                size="mini"
                                icon="el-icon-check"
                                @click="handleConfirmEdit(row)"
                        >
                            确认
                        </el-button>
                        <el-button
                                v-else
                                type="primary"
                                size="mini"
                                icon="el-icon-edit"
                                @click="row.edit=!row.edit"
                        >
                            编辑
                        </el-button>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="改变位置" width="90">
                    <template slot-scope="{}">
                        <i class="drag-handler el-icon-rank"></i>
                    </template>
                </el-table-column>
            </el-table>
            <div class="show-d">
                <el-tag>原始排序 :</el-tag>
                {{ oldList }}
            </div>
            <div class="show-d">
                <el-tag>更新排序 :</el-tag>
                {{ newList }}
            </div>
            <div style="display: flex; justify-content: center; align-items: center; margin-top: 10px">
                <el-button
                        size="mini"
                        type="info"
                        style="margin-right: 40px"
                        @click="handleCancelTable()">
                    退出
                </el-button>
                <el-button size="mini" type="primary"
                           style="background-color: #2362FB; border-color: #2362FB"
                           @click="handleSubmitTable()">提交
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import Sortable from 'sortablejs'
import {getAdminFieldIndex, putAdminFieldIndex} from '@/api/drugs2.0'

export default {
    name: "therapyTableHead",
    props: {
        isActiveTherapyTableHead: {
            type: Boolean,
            default: false
        }
    },
    async created () {
        await this._getAdminFieldIndex()
    },
    data () {
        return {
            listLoading: false,
            tableData: [],
            tableDataClone: [],
            sortable: null,
            oldList: [],
            newList: []
        }
    },
    methods: {
        async _getAdminFieldIndex () {
            try {
                this.listLoading = true
                var params = {
                    type: 'therapy'
                }
                const res = await getAdminFieldIndex(params)
                this.tableData = res.data
                this.list = this.tableData.map(v => {
                    // this.$set(v, 'edit', false) // https://vuejs.org/v2/guide/reactivity.html
                    v.original_column_label_user_definition = v.column_label_user_definition //  will be used when user click the cancel botton
                    v.original_width = v.width //  will be used when user click the cancel botton
                    return v
                })
                this.oldList = this.list.map(v => v.sort)
                this.newList = this.oldList.slice()
                this.tableDataClone = cloneDeep(this.tableData)
                this.$nextTick(() => {
                    this.setSort()
                })
            } catch (err) {
                //在此处理错误
            } finally {
                this.listLoading = false
            }

        },
        async _putAdminFieldIndex () {
            try {
                var tableHead  = this.tableData
                tableHead.forEach(item => {
                    delete item.original_column_label_user_definition
                    delete item.original_width
                })
                var data = {
                    type: 'therapy',
                    field: tableHead
                }
                var res = await putAdminFieldIndex(data)
                if (res.code == 200) {
                    this.$message({
                        message: '修改成功',
                        type: 'success'
                    })
                    this.handleCancelTable()
                } else {
                    this.$message({
                        message: '修改失败',
                        type: 'error'
                    })
                }
            } catch (e) {
                console.log(e)
            }
        },
        handleConfirmEdit (row) {
            row.edit = false
            row.original_column_label_user_definition = row.column_label_user_definition
            row.original_width = row.width
        },
        handleCancelEditLabel (row) {
            row.column_label_user_definition = row.original_column_label_user_definition
            row.edit = false
        },
        handleCancelEditWidth (row) {
            row.width = row.original_width
            row.edit = false
        },
        changeExamine (row) {  //选择状态 (启用/禁用)
            row.visible = row.visible;
        },
      changeExport(row){
        row.is_export = row.is_export;
      },

        setSort () {
            const el = this.$refs.dragTable.$el.querySelectorAll('.el-table__body-wrapper > table > tbody')[0]
            this.sortable = Sortable.create(el, {
                ghostClass: 'sortable-ghost', // Class name for the drop placeholder,
                setData: function (dataTransfer) {
                    // to avoid Firefox bug
                    // Detail see : https://github.com/RubaXa/Sortable/issues/1012
                    dataTransfer.setData('Text', '')
                },
                onEnd: evt => {
                    const targetRow = this.list.splice(evt.oldIndex, 1)[0]
                    // for show the changes, you can delete in you code
                    const tempIndex = this.newList.splice(evt.oldIndex, 1)[0]
                    this.newList.splice(evt.newIndex, 0, tempIndex)
                }
            })
        },
        handleCancelTable () {
            this.$emit('_getAdminFieldIndex')
            this.$emit('update:isActiveTherapyTableHead', false)
        },
        handleCancel (e) {
            this.$emit('_getAdminFieldIndex')
            this.$emit('update:isActiveTherapyTableHead', false)
        },
        async handleSubmitTable () {
            this.newList.forEach((item, index) => {
                this.tableData[item - 1].sort = index + 1
                delete this.tableData[item - 1].original_width
                delete this.tableData[item - 1].original_column_label_user_definition
            })
            await this._putAdminFieldIndex()
                  // window.location.reload();
      this.$emit("_getAdminFieldIndex");
      this.$emit("getList");

        }
    }
}
</script>
<style>
.sortable-ghost {
    opacity: .8;
    color: #fff !important;
    background: #42b983 !important;
}
</style>

<style scoped lang="scss">
/* switch按钮样式 */
::v-deep.switch {
  .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }

  /*打开时文字位置设置*/
  .el-switch__label--right {
    z-index: 1;
  }

  /* 调整打开时文字的显示位子 */
  .el-switch__label--right span {
    margin-right: 14px;
  }

  /*关闭时文字位置设置*/
  .el-switch__label--left {
    z-index: 1;
  }

  /* 调整关闭时文字的显示位子 */
  .el-switch__label--left span {
    margin-left: 14px;
  }

  /*显示文字*/
  .el-switch__label.is-active {
    display: block;
  }

  /* 调整按钮的宽度 */
  .el-switch__core, .el-switch__label {
    width: 55px !important;
    margin: 0;
  }
}

.edit-input {
  padding-right: 80px;
}

.cancel-btn {
  position: absolute;
  right: 15px;
}

.icon-star {
  margin-right: 2px;
}

.drag-handler {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.show-d {
  margin-top: 15px;
}
</style>
