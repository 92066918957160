// 客情管理接口
import request from '@/utils/request'

// 获取客情列表
export function getStaffIndex(params) {
  return request({
    url: '/admin/staff/index',
    method: 'GET',
    params
  })
}

// 新增加客情
export function postStaffSave(data) {
  return request({
    url: '/admin/staff/save',
    method: 'POST',
    data
  })
}

// 修改客情
export function putStaffUpdate(id, data) {
  return request({
    url: `/admin/staff/update/${id}`,
    method: 'PUT',
    data
  })
}

// 修改客情状态
export function putStaffStatus(id, data) {
  return request({
    url: `/admin/staff/status/${id}`,
    method: 'PUT',
    data
  })
}

// 修改客情密码 /admin/staff/pwd/:id
export function putStaffPwd(id, data) {
  return request({
    url: `/admin/staff/pwd/${id}`,
    method: 'PUT',
    data
  })
}

// 获取商户列表
export function getCommonMerchants(id, params) {
  return request({
    url: `admin/common/merchants`,
    method: 'GET',
    params
  })
}
